.main-menu-mobile {
  position: fixed;
  background: $primary;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &.open {
    opacity: 0.9;
    visibility: visible;
    height: 100%;
    z-index: 20;
    li {
      animation: fadeInRight 0.5s ease forwards;
      animation-delay: 0.35s;
      &:nth-of-type(2) {
        animation-delay: 0.4s;
      }
      &:nth-of-type(3) {
        animation-delay: 0.45s;
      }
      &:nth-of-type(4) {
        animation-delay: 0.5s;
      }
      &:nth-of-type(5) {
        animation-delay: 0.55s;
      }
      &:nth-of-type(6) {
        animation-delay: 0.6s;
      }
    }
  }
  ul {
    font-size: 30px;
    font-family: $font-family-heading;
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 0;
    flex: 0;
    li {
      display: block;
      position: relative;
      opacity: 0;
      padding: 10px;
      a {
        display: block;
        position: relative;
        color: #ffffff;
        text-decoration: none;
        overflow: hidden;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
.lock-scroll {
  overflow: hidden;
}
